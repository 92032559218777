import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useServerContext } from '@salesforce/pwa-kit-react-sdk/ssr/universal/hooks'
import { isServer, useConfig } from 'msp-integrations'
import { MainWrapper, Headline } from 'msp-components'
import PageLayout from '@/components/cms/page-layout'
import Seo from '@/components/seo'

const PageNotFound = ({ page }: any) => {
  const { formatMessage } = useIntl()
  const {
    theme: { font }
  } = useConfig()

  const messages = defineMessages({
    seoTitle: {
      id: 'msp.page_not_found.seo.title',
      defaultMessage: '404 Page Not found'
    },
    seoDescr: {
      id: 'msp.page_not_found.seo.description',
      defaultMessage: "The page you're looking for can't be found."
    },
    title: {
      id: 'msp.page_not_found.title',
      defaultMessage:
        'Sorry - something went wrong. Your tea journey can still continue.'
    },
    descrIntro: {
      id: 'msp.page_not_found.descr.intro',
      defaultMessage: 'Back to {homepage} or call us free of charge.'
    },
    descrTel: {
      id: 'msp.page_not_found.descr.tel',
      defaultMessage: '00800 7000 6000'
    },
    descrTime: {
      id: 'msp.page_not_found.descr.time',
      defaultMessage: 'Mon-Sun, 7:00 am - 10:00 pm'
    },
    homepage: {
      id: 'msp.page_not_found.button.home',
      defaultMessage: 'homepage'
    }
  })

  return (
    <>
      <Seo
        title={
          page.found && page?.meta?.title
            ? page?.meta?.title
            : formatMessage(messages.seoTitle)
        }
        description={
          page.found && page?.meta?.description
            ? page?.meta?.description
            : formatMessage(messages.seoDescr)
        }
        keywords={
          page.found && page?.meta?.keywords ? page?.meta?.keywords : ''
        }
        canonicalPath={'/page-not-found'}
        noIndex
      />
      <MainWrapper className='not-found-container' fullWidth={true}>
        <div className='flex h-full h-screen flex-row'>
          <div className='sm:w-full'></div>
          <div className='flex h-full w-full flex-col justify-center px-8 max-sm:bg-black/50'>
            <Headline
              tag='h1'
              className='text-32 md:text-36 text-white sm:max-w-[500px]'
            >
              {formatMessage(messages.title)}
            </Headline>

            <p
              className={`${font.regular} text-20 md:text-16 pt-6 font-light text-white`}
            >
              {formatMessage(messages.descrIntro, {
                homepage: (
                  <a href='/' className='underline'>
                    {formatMessage(messages.homepage)}
                  </a>
                )
              })}
            </p>
            <p>{formatMessage(messages.descrTel)}</p>
            <p>{formatMessage(messages.descrTime)}</p>
          </div>
        </div>
      </MainWrapper>
    </>
  )
}

const PageNotFoundWrapper = (props) => {
  const { res } = useServerContext()

  if (isServer) {
    res.status(404)
  }

  return <PageLayout {...Object.assign({ ChildPage: PageNotFound }, props)} />
}

export default PageNotFoundWrapper
